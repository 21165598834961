
import { Component, Vue } from "vue-property-decorator";
import ResearchPageHeader from "@/components/ResearchPageHeader.vue";
import ResearchsSearchForm, {
  SearchParamsObject,
} from "@/components/ResearchsSearchForm.vue";
import ExportButton from "@/components/ExportToExcelButton.vue";
import DataFormatControlsContainer from "@/components/DataFormatControlsContainer.vue";
import {
  RankedTvNetworkDTO,
  RankingOfTvNetworksExportParamsDTO,
  RankingOfTvNetworksSearchParamsDTO,
} from "@/data/dto/ranking-of-tv-networks.dto";
import {
  formatNumberToStringWithSymbol,
  ViewersProjectionBaseType,
  numberToBrString,
} from "@/utils/number.util";
import { TargetBaseType, getTargetBaseTypeByTargetName, generateViewersProjectionLabel } from '@/utils/viewers-projection.util';

@Component({
  components: {
    ResearchPageHeader,
    ResearchsSearchForm,
    DataFormatControlsContainer,
    ExportButton,
  },
})
export default class RankingOfTvNetwork extends Vue {
  selectedTargetBaseType: TargetBaseType = 'residences';
  decimalPlaces = 0;
  excelHeader = "Ranking de Canais";
  ranking: RankedTvNetworkDTO[] = [] as RankedTvNetworkDTO[];
  currentSearchParams: {
    valueOnly: RankingOfTvNetworksSearchParamsDTO;
    completeData: Record<string, any>;
  } | null = null;
  viewersProjectionBase: ViewersProjectionBaseType = {
    value: 1000000,
    label: "Milhão",
    baseSymbol: "M",
    decimalPlaces: 1,
  };

  async exportExcel(): Promise<void> {
    const exportButton = this.$refs.exportButton as ExportButton;
    const params = this.exportToExcelSearchParams;
    await exportButton.exportAndDownload(params);
  }

  async getRankingOfTvNetworks(params: SearchParamsObject): Promise<void> {
    this.ranking = [];

    const searchParams = params.valueOnly as RankingOfTvNetworksSearchParamsDTO;

    if (searchParams.rankingTimeRanges.length === 0) {
      this.$store.commit("showAlert", {
        message: "Nenhuma faixa horária selecionada!",
        type: "warning",
      });
      return;
    }

    if (params.completeData.target) {
      this.selectedTargetBaseType = getTargetBaseTypeByTargetName(params.completeData.target.label);
    }

    const rankingResponse = await this.$store.dispatch("getRankingOfTvNetworks", searchParams);

    if (!rankingResponse) {
      this.$store.commit('showAlert', {
        message: 'Não há dados disponíveis para a consulta realizada.',
        type: 'warning',
      });
      return;
    }

    const { ranking } = rankingResponse;

    this.currentSearchParams = {
      valueOnly: searchParams,
      completeData: params.completeData,
    };
    this.ranking = ranking;
  }

  get fields() {
    return [
      {
        key: "order",
        label: "Ordem",
        sortable: true,
      },
      {
        key: "tvNetworkId",
        label: "Sigla",
        sortable: false,
      },
      {
        key: "tvNetworkName",
        label: "Emissora",
        class: "text-left",
        sortable: false,
      },
      {
        key: "audience",
        label: "AUD%",
        sortable: false,
        formatter: (value: number): string | number => {
          return this.formattedDecimalPlaces(value);
        },
      },
      {
        key: "share",
        label: "SH%",
        sortable: false,
        formatter: (value: number): string | number => {
          return this.formattedDecimalPlaces(value);
        },
      },
      {
        key: "viewersProjection",
        label: this.viewersProjectionLabel,
        sortable: false,
        formatter: (value: number): string => {
          return this.formattedViewersProjection(value);
        },
      },
    ];
  }

  get viewersProjectionLabel(): string {
    return `AUD ${generateViewersProjectionLabel(this.selectedTargetBaseType)}`;
  }

  get exportToExcelSearchParams(): RankingOfTvNetworksExportParamsDTO | null {
    if (!this.currentSearchParams) return null;
    return {
      ranking: {
        header: this.excelHeader,
        params: this.currentSearchParams.valueOnly,
      },
    } as RankingOfTvNetworksExportParamsDTO;
  }

  formattedViewersProjection(
    numberOfViewers: number,
    base: ViewersProjectionBaseType = this.viewersProjectionBase
  ): string {
    if (!numberOfViewers) return "-";
    return formatNumberToStringWithSymbol(numberOfViewers, base);
  }

  onDefaultFiltersValuesLoaded(params: SearchParamsObject): void {
    this.decimalPlaces = params.valueOnly.decimalPlaces || 0;
  }

  onViewersProjectionBaseChange(base: ViewersProjectionBaseType): void {
    this.viewersProjectionBase = base;
  }

  formattedDecimalPlaces(
    n: number,
    decimalPlaces = this.decimalPlaces
  ): number | string {
    if (!n) return "-";
    return numberToBrString(n, decimalPlaces);
  }

  onDecimalPlacesChange(decimalPlaces: number): void {
    this.decimalPlaces = decimalPlaces;
  }
}
